<template>
  <PxHeader :activeSearchMobile="false" />
  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else class="bg-agrogo-green-2 sizeMinAllView">
    <div class="container-bg-myaccount-desktop-legalnaturalperson">
      <div class="bg-agrogo-green-2 bg-agrogo-green-gradient">
        <div class="container-myaccount pt-4 pb-8 md:pt-10 md:pb-12">
          <div class="px-14 mb-8 md:px-6 md:mb-12 flex space-x-4 md:space-x-6">
            <p
              class="font-nexa font-normal text-base text-white text-left md:text-2xl"
            >
              Mi Perfil
            </p>
            <!-- <p
              class="font-dinpro font-normal text-base text-agrogo-yellow-1 cursor-pointer text-right md:text-2xl"
            >
              Editar <span class="icon-edit"></span>
            </p> -->
          </div>
          <div
            class="container-forms pt-0 grid grid-cols-1 md:grid-cols-2 md:gap-x-2"
          >
            <div>
              <label
                for="name"
                class="text-xs text-agrogo-gray-1 font-nexa font-normal block ml-2 mb-1 text-left md:text-base md:mb-2"
                >Nombres</label
              >
              <div class="relative">
                <div
                  class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                >
                  <span
                    class="icon-Perfil_2 text-agrogo-black-1 text-lg"
                  ></span>
                </div>
                <input
                  v-model="$v.formData.name.$model"
                  @blur="$v.formData.name.$touch()"
                  id="name"
                  name="name"
                  type="text"
                  disabled
                  class="input-login"
                  :class="{ 'error-form': $v.formData.name.$error }"
                />
              </div>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-if="$v.formData.name.$error"
              >
                {{ messageValidation($v.formData.name) }}
              </p>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-else
              >
                &nbsp;
              </p>
            </div>
            <div>
              <label
                for="lastname"
                class="text-xs text-agrogo-gray-1 font-nexa font-normal block ml-2 mb-1 text-left md:mt-0 md:text-base md:mb-2"
                >Apellidos</label
              >
              <div class="relative">
                <div
                  class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                >
                  <span
                    class="icon-Perfil_2 text-agrogo-black-1 text-lg"
                  ></span>
                </div>
                <input
                  v-model="$v.formData.lastname.$model"
                  @blur="$v.formData.lastname.$touch()"
                  id="lastname"
                  name="lastname"
                  type="text"
                  disabled
                  class="input-login"
                  :class="{ 'error-form': $v.formData.lastname.$error }"
                />
              </div>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-if="$v.formData.lastname.$error"
              >
                {{ messageValidation($v.formData.lastname) }}
              </p>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-else
              >
                &nbsp;
              </p>
            </div>
            <div>
              <label
                for="identy_document"
                class="text-xs text-agrogo-gray-1 font-nexa font-regular block ml-2 mb-1 text-left md:text-base md:mb-2"
                >Cédula</label
              >
              <div class="relative">
                <div
                  class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                >
                  <span class="icon-cedula text-agrogo-black-1 text-lg"></span>
                </div>
                <input
                  v-model="$v.formData.identy_document.$model"
                  @blur="$v.formData.identy_document.$touch()"
                  id="identy_document"
                  name="identy_document"
                  type="text"
                  disabled
                  class="input-login"
                  :class="{ 'error-form': $v.formData.identy_document.$error }"
                />
              </div>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-if="$v.formData.identy_document.$error"
              >
                {{ messageValidation($v.formData.identy_document) }}
              </p>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-else
              >
                &nbsp;
              </p>
            </div>
            <div>
              <label
                for="email-address"
                class="text-xs text-agrogo-gray-1 font-nexa font-normal block ml-2 mb-1 text-left md:text-base md:mb-2"
                >Email</label
              >
              <div class="relative">
                <div
                  class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                >
                  <span class="icon-mail text-agrogo-black-1 text-lg"></span>
                </div>
                <input
                  v-model="$v.formData.email.$model"
                  @blur="$v.formData.email.$touch()"
                  id="email-address"
                  name="email"
                  disabled
                  type="email"
                  class="input-login"
                  :class="{ 'error-form': $v.formData.email.$error }"
                />
              </div>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-if="$v.formData.email.$error"
              >
                {{ messageValidation($v.formData.email) }}
              </p>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-else
              >
                &nbsp;
              </p>
            </div>
          </div>
          <div class="container-forms pt-0 grid grid-cols-1">
            <div>
              <label
                for="indicativo_telephone"
                class="text-xs text-agrogo-gray-1 font-nexa block ml-2 mb-1 text-left md:text-base md:mb-2"
                >Teléfono
                <span class="icon-whatsapp"></span>
                Whatsapp</label
              >
              <div class="flex">
                <div class="relative w-2/5 md:w-3/12 mr-2">
                  <img :src="img" class="img-code" />
                  <v-select
                    :options="codes"
                    v-model="selected"
                    :reduce="(option) => option.id"
                    class="style-chooser"
                    label="phone_code"
                    disabled
                  >
                    <template
                      #option="{ phone_code, path_img_iso_large, name }"
                    >
                      <div class="flex items-center justify-between">
                        <div class="flex">
                          <img
                            :src="path_img_iso_large"
                            :alt="phone_code"
                            class="mr-2"
                          />
                          <div class="font-medium">{{ phone_code }}</div>
                        </div>
                        <div class="font-medium">
                          <div v-if="name.length > 20">
                            {{ name.slice(0, 20) }}..
                          </div>
                          <div v-else>{{ name }}</div>
                        </div>
                      </div>
                    </template>
                    <template #no-options>Sin opciones</template>
                  </v-select>
                </div>
                <div class="relative w-3/5 md:w-9/12">
                  <div
                    class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                  >
                    <span
                      class="icon-telefono text-agrogo-black-1 text-lg"
                    ></span>
                  </div>
                  <input
                    v-model="$v.formData.telephone.$model"
                    @blur="$v.formData.telephone.$touch()"
                    id="telephone"
                    name="telephone"
                    type="text"
                    disabled
                    class="input-login"
                    :class="{ 'error-form': $v.formData.telephone.$error }"
                  />
                </div>
              </div>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-if="$v.formData.telephone.$error"
              >
                {{ messageValidation($v.formData.telephone) }}
              </p>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-else
              >
                &nbsp;
              </p>
            </div>
            <!-- <div>
              <label
                for="password"
                class="text-xs text-agrogo-gray-1 font-nexa font-normal block ml-2 mb-1 text-left md:text-base md:mb-2"
                >Contraseña</label
              >
              <div class="relative">
                <div
                  class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                >
                  <span
                    class="icon-password text-agrogo-black-1 text-base"
                  ></span>
                </div>
                <input
                  v-model="$v.formData.password.$model"
                  @blur="$v.formData.password.$touch()"
                  id="password"
                  name="password"
                  type="password"
                  class="input-login"
                  :class="{ 'error-form': $v.formData.password.$error }"
                />
              </div>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-if="$v.formData.password.$error"
              >
                {{ messageValidation($v.formData.password) }}
              </p>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-else
              >
                &nbsp;
              </p>
            </div>
            <div>
              <label
                for="password"
                class="text-xs text-agrogo-gray-1 font-nexa font-normal block ml-2 mb-1 text-left md:text-base md:mb-2"
                >Confirmar contraseña</label
              >
              <div class="relative">
                <div
                  class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                >
                  <span
                    class="icon-password_2 text-agrogo-black-1 text-lg"
                  ></span>
                </div>
                <input
                  v-model="$v.formData.password_confirmation.$model"
                  @blur="$v.formData.password_confirmation.$touch()"
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  class="input-login"
                  :class="{
                    'error-form': $v.formData.password_confirmation.$error,
                  }"
                />
              </div>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-if="$v.formData.password_confirmation.$error"
              >
                {{ messageValidation($v.formData.password_confirmation) }}
              </p>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-else
              >
                &nbsp;
              </p>
            </div> -->
          </div>
          <!-- <div
            class="container-forms pt-0 grid grid-cols-1 md:grid-cols-2 md:gap-x-2"
          >
            <div>
              <label
                for="identy_document"
                class="text-xs text-agrogo-gray-1 font-nexa font-regular block ml-2 mb-1 text-left md:text-base md:mb-2"
                >Entidad Bancaria</label
              >
              <div class="relative">
                <div
                  class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                >
                  <span class="icon-cedula text-agrogo-black-1 text-lg"></span>
                </div>
                <input
                  v-model="$v.formData.identy_document.$model"
                  @blur="$v.formData.identy_document.$touch()"
                  type="text"
                  class="input-login"
                  :class="{ 'error-form': $v.formData.identy_document.$error }"
                />
              </div>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-if="$v.formData.identy_document.$error"
              >
                {{ messageValidation($v.formData.identy_document) }}
              </p>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-else
              >
                &nbsp;
              </p>
            </div>
            <div>
              <label
                for="identy_document"
                class="text-xs text-agrogo-gray-1 font-nexa font-regular block ml-2 mb-1 text-left md:text-base md:mb-2"
                >Número de Cuenta</label
              >
              <div class="relative">
                <div
                  class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                >
                  <span class="icon-cedula text-agrogo-black-1 text-lg"></span>
                </div>
                <input
                  v-model="$v.formData.identy_document.$model"
                  @blur="$v.formData.identy_document.$touch()"
                  type="text"
                  class="input-login"
                  :class="{ 'error-form': $v.formData.identy_document.$error }"
                />
              </div>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-if="$v.formData.identy_document.$error"
              >
                {{ messageValidation($v.formData.identy_document) }}
              </p>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-else
              >
                &nbsp;
              </p>
            </div>
          </div> -->
          <div class="container-forms pt-0 grid grid-cols-1">
            <!-- <div>
              <label
                for="identy_document"
                class="text-xs text-agrogo-gray-1 font-nexa font-regular block ml-2 mb-1 text-left md:text-base md:mb-2"
                >Tipo de Cuenta</label
              >
              <div class="relative">
                <div
                  class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                >
                  <span class="icon-cedula text-agrogo-black-1 text-lg"></span>
                </div>
                <input
                  v-model="$v.formData.identy_document.$model"
                  @blur="$v.formData.identy_document.$touch()"
                  type="text"
                  class="input-login"
                  :class="{ 'error-form': $v.formData.identy_document.$error }"
                />
              </div>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-if="$v.formData.identy_document.$error"
              >
                {{ messageValidation($v.formData.identy_document) }}
              </p>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-else
              >
                &nbsp;
              </p>
            </div>
            <div class="flex mb-4 md:mb-5">
              <div class="flex items-start mt-1.5 md:mt-2">
                <input
                  v-model="$v.formData.verify_whatsapp.$model"
                  @blur="$v.formData.verify_whatsapp.$touch()"
                  :true-value="true"
                  :false-value="false"
                  id="verify_whatsapp"
                  name="verify_whatsapp"
                  type="checkbox"
                  class="custom-checkbox-test"
                />
              </div>
              <div class="flex text-left md:mr-28">
                <label
                  for="verify_whatsapp"
                  class="text-white text-sm font-dinpro font-normal block ml-3 md:text-base"
                >
                  Has aceptado recibir mensajes por parte de
                  <span class="text-agrogo-gray-1 text-sm font-nexa font-black"
                    >AGRO GO</span
                  >
                  vía Whatsapp y Email
                </label>
              </div>
            </div>
            <div class="flex mb-4 md:mb-12">
              <div class="flex items-start mt-1.5 md:mt-2">
                <input
                  v-model="$v.formData.verify_tc.$model"
                  @blur="$v.formData.verify_tc.$touch()"
                  :true-value="true"
                  :false-value="false"
                  id="verify_tc"
                  name="verify_tc"
                  type="checkbox"
                  class="custom-checkbox-test"
                />
              </div>
              <div class="flex items-end text-left md:mr-24">
                <label
                  for="verify_tc"
                  class="text-white text-sm font-dinpro font-normal block ml-3 md:text-base"
                >
                  Has aceptado nuestra política de privacidad y términos y
                  condiciones.
                </label>
              </div>
            </div> -->
            <div>
              <button type="button" class="btn-login" @click.prevent="recovery">
                Cambiar contraseña
              </button>
            </div>
            <div
              class="mt-4 text-left bg-agrogo-yellow-1 p-2"
              v-if="errors.length > 0"
            >
              <ul class="ul">
                <li
                  class="text-agrogo-green-2 font-nexa text-sm mb-1"
                  v-for="(item, index) in errors"
                  :key="index"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PxHeader from "@/components/header/PxHeader";
import validationMixin from "@/mixins/validationMixin";
import loginRegisterMixin from "@/mixins/loginRegisterMixin";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  sameAs,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
import validationToken from "@/mixins/validationToken";

export default {
  inject: ["provider"],
  mixins: [validationMixin, loginRegisterMixin, validationToken],
  data() {
    return {
      $v: useVuelidate(),
      /**
       * Variable that stores the user data returned by the api
       * @type {Object}
       */
      formData: {
        id: "",
        name: "",
        lastname: "",
        telephone: "",
        code_area_id: null,
        identy_document: "",
        verify_whatsapp: false,
        type_user_id: "2" /* Here is the type of user*/,
        verify_tc: false,
        password: "",
        password_confirmation: "",
        email: "",
      },
      /**
       * Variable that enables or disables the loading gif
       * @type {Boolean}
       */
      isBusy: false,
      /**
       * Variable that allows access to the methods of the provider authRepository
       * @type {Object}
       */
      repository: this.provider.authRepository,
      /**
       * Variable that allows access to the methods of the provider myFarmRepository
       * @type {Object}
       */
      repositoryProfile: this.provider.profileRepository,
      /**
       * Variable that stores the data of the api that consults the country codes for cell phone numbers
       * @type {Array}
       */
      codes: [],
    };
  },
  components: {
    PxHeader,
  },
  validations() {
    return {
      formData: {
        name: {
          required,
        },
        lastname: {
          required,
        },
        telephone: {
          required,
          numeric,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
        identy_document: {
          required,
          numeric,
        },
        password: {
          required,
        },
        password_confirmation: {
          required,
          sameAsPassword: sameAs(this.formData.password),
        },
        email: {
          required,
          email,
        },
        verify_whatsapp: {
          checked(val) {
            return val;
          },
        },
        verify_tc: {
          checked(val) {
            return val;
          },
        },
      },
    };
  },
  methods: {
    /**
     * makes a request to the agrogo api to obtain the telephone codes by country and loads the images corresponding to the code.
     * @returns  {void}
     */
    async loadCodeAreaAcount() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAdicional = await generateHash();
        const { data } = await me.repository.getAll(dataAdicional);

        data.data.forEach((el) => {
          el.phone_code = `+${el.phone_code}`;
          el.path_img_iso_medium = `${API_ROUTES.image.get}${el.path_img_iso_medium}`;
          el.path_img_iso_large = `${API_ROUTES.image.get}${el.path_img_iso_large}`;
        });
        me.codes = data.data;
        me.formData.code_area_id = 44;
        me.selected = 44;
        me.loadImg(me.formData.code_area_id);
      } catch (error) {
        console.log(error);
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy = false;
      }
    },
    /**
     * Query the user profile api and return the information of the logged in user.
     * @returns  {void}
     */
    async getProfile() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const { data: data } = await me.repositoryProfile.find(dataAditional);
        var dataNatural = data.response;
        me.formData = {
          id: dataNatural.id,
          name: dataNatural.name,
          lastname: dataNatural.lastname,
          telephone: dataNatural.telephone,
          code_area_id: dataNatural.phone_code.id,
          identy_document: dataNatural.identy_document,
          verify_whatsapp: false,
          type_user_id: "1" /* Here is the type of user*/,
          verify_tc: false,
          password: "",
          password_confirmation: "",
          email: dataNatural.email,
        };
        me.selected = me.formData.code_area_id;
        me.loadImg(me.formData.code_area_id);
      } catch (error) {
        console.log(error);
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy = false;
      }
    },
    recovery() {
      const me = this;
      me.$router.push({
        name: "PasswordRecovery2",
        query: { telephone: me.formData.telephone },
      });
    },
  },
  async mounted() {
    const me = this;
    await me.loadCodeAreaAcount();
    await me.getProfile();
  },
};
</script>

<style></style>
